import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllEvents } from "../api/eventsApi";


export const fetchEventsAsync = createAsyncThunk(
    'events/getAllEvents',
    async () => {
      const data = await getAllEvents();
      return data;
    }
  );
  
  // Step 3: Define a slice using createSlice to manage the state
  const eventsSlice = createSlice({
    name: 'events',
    initialState: {
      events: null,
      status: 'idle',
      error: null,
      selectedEvent: null,
     
    },
    reducers: {
      selectEvent: (state, action) => {
        state.selectedEvent = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchEventsAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchEventsAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.events = action.payload;
        })
        .addCase(fetchEventsAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });

  export const { selectEvent} = eventsSlice.actions;

export default eventsSlice.reducer;
