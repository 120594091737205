import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
//import { css } from "styled-components/macro"; //eslint-disable-line

// import  { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase } from "./header.js";

import Sliders from "./Slider.js";



// const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-0 bg-center bg-cover h-screen min-h-16 mb-48`}

`;



export default () => {
  

  return (
    <>

      <Sliders />
      
    </>
  );
};
