import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    event: null,
    quantity: 0,
    total: 0,
    customer_details: null
  },
  reducers: {
    addEvent: (state, action) => {
      state.quantity = action.payload.quantity;
      state.event = action.payload;
      state.total = action.payload.priceValue * action.payload.quantity;
      state.customer_details = action.payload.customerDetails;
    },
    removeEvent: (state, action) => {
      state.quantity = 0;
      state.event = null;
      state.total = 0;
      state.customer_details = null;
    },
    updateCart: (state, action) => {
      if (action.payload.event) {
        state.quantity = action.payload.quantity;
        state.total = action.payload.quantity * action.payload.event.priceValue;
      }
    },
  },
});

export const { addEvent, removeEvent, updateCart } = cartSlice.actions;
export default cartSlice.reducer;
