import React from "react";
import GlobalStyles from './styles/GlobalStyles';
// import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "pages/Homepage";
import Contactus from "pages/Contactus";
import Faqpage from "pages/Faqpage";
import EventDetails from "pages/EventDetails";
import Cart from "pages/Cart";
import ThankYouPage from "pages/ThankYouPage";
import FailurePage from "pages/FailurePage";

import EventList from "pages/EventList";

function App() {
  return (
    <div>
    <GlobalStyles />
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/faq" element={<Faqpage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/events" element={<EventList />} />
        <Route path="/event-detail/:id" element={<EventDetails />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/failure" element={<FailurePage />} />
        {/* <Route path="/get-ticket" element={<Ticket />} /> */}
      </Routes>
    </Router>
  </div>
  );
}

export default App;
