import Footer from 'components/Footer'
import Header from 'components/header'
import TabCardGrid from 'components/TabCardGrid'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchEventsAsync } from "../redux/eventRedux";

function EventList() {
  const { events, status, error } = useSelector((state) => state.events);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchEventsAsync());
    
  }, [dispatch]);

  return (
        <AnimationRevealPage >
    <Header />
    
    <TabCardGrid events={events} status={status} error={error} />

    
    <Footer />
  </AnimationRevealPage>
        
    
  )
}

export default EventList