export const eventsData = {"data":{"events":[
 
    { 
      "objectId":"60a73c6fb8bce667e4724d19",
      "title": "Lightup Music Concert",
      "eventDate": "2024-03-09 14:00:00",
      "address": "123 Main Street",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-09 12:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1708698653/Lightup_ph_sb17er.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1708698653/Lightup_ph_sb17er.jpg","https://example.com/event/poster1_1.jpg"],
      "createdAt": "2024-02-02:1100"
    },
    
    {
      "objectId":"1241adice0033eekzcwl2w98920",  
      "title": "Lightup Music Concert",
      "eventDate": "2024-03-09 16:00:00",
      "address": "123 Main Street",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-09 16:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1708872720/light_up_evfk3c.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1708872720/light_up_evfk3c.jpg"],
      "createdAt": "2024-02-03:1200"
    },
    {
      "_id": "65e401d0c3d212994ba2050b",
      "objectId":"60a73cb2b8bce667e4724d23",
      "title": "Lightup Event Party",
      "eventDate": "2024-03-09 16:00:00",
      "address": "Aztec Arcum, Stadium Rd, PHC",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-09 16:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_1_Large_k9k2zm.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_1_Large_k9k2zm.jpg"],
      "createdAt": "2024-02-04:1300"
    },
    {
      "objectId":"60a73ca6b8bce667e4724d21",  
      "title": "Lightup Music Concert",
      "eventDate": "2024-02-22 16:00:00",
      "address": "101 Pine Street",
      "city": "Calabar",
      "state": "Cross River",
      "ticketCategories": [
        { "name": "Premium", "price": "75,000", "qty": "25","discount:":"0.0" },
        { "name": "Standard", "price": "15,000", "qty": "150","discount:":"0.0" },
        { "name": "Economy", "price": "5,000", "qty": "400","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-12 18:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003858/light_6_Large_gbydoe.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003858/light_6_Large_gbydoe.jpg","https://example.com/event/poster4_1.jpg"],
      "createdAt": "2024-02-05:1400"
    },
    {
      "objectId":"60a73cabb8bce667e4724d22",  
      "title": "Lightup Port Harcourt",
      "eventDate": "2024-02-23 16:00:00",
      "address": "222 Maple Street",
      "city": "Kano",
      "state": "Kano",
      "ticketCategories": [
        { "name": "Deluxe", "price": "50,000", "qty": "40","discount:":"0.0" },
        { "name": "General", "price": "10,000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-13 20:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003858/light_1_Large_q6wx4o.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003858/light_1_Large_q6wx4o.jpg",
               "https://example.com/event/poster5_2.jpg"],
      "createdAt": "2024-02-06:1500"
    },
    {
      "objectId":"60a73c9fb8bce667e4724d20",  
      "title": "Lightup Music Concert",
      "eventDate": "2024-02-24 16:00:00",
      "address": "333 Cedar Street",
      "city": "Enugu",
      "state": "Enugu",
      "ticketCategories": [
        { "name": "Exclusive", "price": "100,000", "qty": "15","discount:":"0.0" },
        { "name": "Standard", "price": "25,000", "qty": "100","discount:":"0.0" },
        { "name": "Budget", "price": "5000", "qty": "300","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-14 16:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003858/light_2_Large_ah1hds.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003858/light_2_Large_ah1hds.jpg"],
      "createdAt": "2024-02-07:1600"
    },
    {
      "objectId":"60a73c99b8bce667e4724d1f",
      "title": "Lightup Port Harcourt",
      "eventDate": "2024-02-25 16:00:00",
      "address": "444 Walnut Street",
      "city": "Ibadan",
      "state": "Oyo",
      "ticketCategories": [
        { "name": "Premium", "price": "750,000", "qty": "20","discount:":"0.0" },
        { "name": "Standard", "price": "150,000", "qty": "100","discount:":"0.0" },
        { "name": "Student", "price": "5,000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-15 16:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003857/light_4_Large_u9g8ib.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003857/light_4_Large_u9g8ib.jpg",
                "https://example.com/event/poster7_1.jpg",
                "https://example.com/event/poster7_2.jpg",
                 "https://example.com/event/poster7_3.jpg"],
      "createdAt": "2024-02-08:1700"
    },
    {
      "objectId":"60a73c99b8bce667e4724d1f",
      "title": "Lightup Music Concert",
      "eventDate": "2024-02-26 16:00:00",
      "address": "555 Oak Street",
      "city": "Abeokuta",
      "state": "Ogun",
      "ticketCategories": [
        { "name": "Platinum", "price": "1,000,000", "qty": "25","discount:":"0.0" },
        { "name": "Gold", "price": "250,000", "qty": "150","discount:":"0.0" },
        { "name": "Silver", "price": "20,000", "qty": "300","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-16 16:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709003853/light_3_Small_cja1zh.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003853/light_3_Small_cja1zh.jpg"],
      "createdAt": "2024-02-09:18"
    },
    {
      "objectId":"60a73c92b8bce667e4724d1e",
      "title": "Lightup Port Harcourt",
      "eventDate": "2024-02-27:10",
      "address": "666 Maple Street",
      "city": "Akure",
      "state": "Ondo",
      "ticketCategories": [
        { "name": "Premium", "price": "500,000", "qty": "30","discount:":"0.0" },
        { "name": "Standard", "price": "10,000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-17 18:00:00",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1708872720/light_up_evfk3c.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003857/light_4_Large_u9g8ib.jpg"],
      "createdAt": "2024-02-10:1900"
    },
    // {
    //   "objectId":"60a73c8bb8bce667e4724d1d",
    //   "title": "Lightup Music Concert",
    //   "eventDate": "2024-02-28:2100",
    //   "address": "777 Pine Street",
    //   "city": "Benin City",
    //   "state": "Edo",
    //   "ticketCategories": [
    //     { "name": "Diamond", "price": "200,0000", "qty": "10","discount:":"0.0" },
    //     { "name": "Platinum", "price": "750,000", "qty": "50","discount:":"0.0" },
    //     { "name": "Gold", "price": "150,000", "qty": "200","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-18:21",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/music-event-flyer-template-6300e53b5234e5495c530c74c3621f10_screen_f9uxts.jpg",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/music-event-flyer-template-6300e53b5234e5495c530c74c3621f10_screen_f9uxts.jpg","https://example.com/event/poster10_2.jpg"],
    //   "createdAt": "2024-02-11:2000"
    // },
    // {
    //   "objectId":"60a73c84b8bce667e4724d1c",
    //   "title": "Lightup Port Harcourt",
    //   "eventDate": "2024-03-01:1000",
    //   "address": "888 Elm Street",
    //   "city": "Jos",
    //   "state": "Plateau",
    //   "ticketCategories": [
    //     { "name": "VIP", "price": "500000", "qty": "20","discount:":"0.0" },
    //     { "name": "Standard", "price": "100000", "qty": "100","discount:":"0.0" },
    //     { "name": "Budget", "price": "5000", "qty": "200","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-19:1000",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646893/dj-concert-modern-music-event-poster-design-template-d67abf0fb450a8d6c232f348dd6e6182_screen_c66kvb.jpg",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646893/dj-concert-modern-music-event-poster-design-template-d67abf0fb450a8d6c232f348dd6e6182_screen_c66kvb.jpg"],
    //   "createdAt": "2024-02-12:2100"
    // },
    // {
    //   "objectId":"60a73c7db8bce667e4724d1b",
    //   "title": "Soulful Serenade",
    //   "eventDate": "2024-03-02:12",
    //   "address": "999 Oak Street",
    //   "city": "Kaduna",
    //   "state": "Kaduna",
    //   "ticketCategories": [
    //     { "name": "VIP", "price": "750000", "qty": "25","discount:":"0.0" },
    //     { "name": "Regular", "price": "150000", "qty": "150","discount:":"0.0" },
    //     { "name": "Economy", "price": "5000", "qty": "300","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-20:1200",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/29176bd5-7c9a-461b-909b-5e55153819fd_qy6mn5.png",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/29176bd5-7c9a-461b-909b-5e55153819fd_qy6mn5.png","https://example.com/event/poster12_1.jpg"],
    //   "createdAt": "2024-02-13:2200"
    // },
    // {
    //   "objectId":"60a73c76b8bce667e4724d1a",
    //   "title": "Electronic Dance Fest",
    //   "eventDate": "2024-03-03:14",
    //   "address": "1010 Pine Street",
    //   "city": "Warri",
    //   "state": "Delta",
    //   "ticketCategories": [
    //     { "name": "Diamond", "price": "200,000", "qty": "15","discount:":"0.0" },
    //     { "name": "Gold", "price": "50,000", "qty": "100","discount:":"0.0" },
    //     { "name": "Silver", "price": "10,000", "qty": "200","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-21:14",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646897/music-event-poster-with-photo_52683-42061_ti09k6.jpg",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646897/music-event-poster-with-photo_52683-42061_ti09k6.jpg","https://example.com/event/poster13_2.jpg"],
    //   "createdAt": "2024-02-14:2300"
    // }
  ]
},
"liveEvents":[
 
  { 
    "objectId":"60a73c6fb8bce667e4724d19",
    "title": "Lightup Event Party",
      "eventDate": "2024-03-09 16:00:00.000Z",
      "address": "Aztec Arcum, Stadium Rd, PHC",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
    "ticketClosingDate": "2024-03-09 16:00:00.000Z",
    "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709008042/light_scape_lpgmnz.jpg",
    "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003857/light_4_Large_u9g8ib.jpg"],
    "createdAt": "2024-02-02:1100"
  },
  {
    "objectId":"1241adice0033eekzcwl2w98920",  
    "title": "Lightup Event Party",
      "eventDate": "2024-03-09 16:00:00.000",
      "address": "Aztec Arcum, Stadium Rd, PHC",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-09 16:00:00.000",
    "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003858/light_2_Large_ah1hds.jpg",
    "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003858/light_2_Large_ah1hds.jpg"],
    "createdAt": "2024-02-03:1200"
  }],

  "upcomingEvents":[
 
    { 
      "objectId":"60a73c6fb8bce667e4724d19",
      "title": "Lightup Event Party",
      "eventDate": "2024-03-09:1600",
      "address": "Aztec Arcum, Stadium Rd, PHC",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-09:0800",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709003853/light_3_Small_cja1zh.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003857/light_4_Large_u9g8ib.jpg"],
      "createdAt": "2024-02-02:1100"
    },
    {
      "objectId":"1241adice0033eekzcwl2w98920",  
      "title": "Lightup Event Party",
      "eventDate": "2024-03-09:1600",
      "address": "Aztec Arcum, Stadium Rd, PHC",
      "city": "Port Harcourt",
      "state": "Rivers",
      "ticketCategories": [
        { "name": "Table for 5", "price": "2,000,000", "qty": "20","discount:":"0.0" },
        { "name": "VIP", "price": "25,000", "qty": "200","discount:":"0.0" },
        { "name": "Regular", "price": "5,000", "qty": "1000","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-10:0900",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/c_crop,w_1200,h_850,g_auto,e_improve,e_sharpen/v1709003858/light_2_Large_ah1hds.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709003858/light_2_Large_ah1hds.jpg"],
      "createdAt": "2024-02-03:1200"
    }],
  "pastEvents": [
    {
      "objectId":"60a73c92b8bce667e4724d1e",
      "title": "Indie Showcase",
      "eventDate": "2024-02-27:1800",
      "address": "666 Maple Street",
      "city": "Akure",
      "state": "Ondo",
      "ticketCategories": [
        { "name": "Premium", "price": "500000", "qty": "30","discount:":"0.0" },
        { "name": "Standard", "price": "100000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-17:1800",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646894/canva-dark-blue-night-club-party-poster-iQT5avxTBQ0_ojgbir.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646894/canva-dark-blue-night-club-party-poster-iQT5avxTBQ0_ojgbir.jpg"],
      "createdAt": "2024-02-10:1900"
    },
    {
      "objectId":"60a73c8bb8bce667e4724d1d",
      "title": "Hip Hop Jam",
      "eventDate": "2024-02-28:2100",
      "address": "777 Pine Street",
      "city": "Benin City",
      "state": "Edo",
      "ticketCategories": [
        { "name": "Diamond", "price": "2000000", "qty": "10","discount:":"0.0" },
        { "name": "Platinum", "price": "750000", "qty": "50","discount:":"0.0" },
        { "name": "Gold", "price": "150000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-18:2100",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/music-event-flyer-template-6300e53b5234e5495c530c74c3621f10_screen_f9uxts.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/music-event-flyer-template-6300e53b5234e5495c530c74c3621f10_screen_f9uxts.jpg","https://example.com/event/poster10_2.jpg"],
      "createdAt": "2024-02-11:2000"
    },
    {
      "objectId":"60a73c84b8bce667e4724d1c",
      "title": "Reggae Rhythms",
      "eventDate": "2024-03-01:1000",
      "address": "888 Elm Street",
      "city": "Jos",
      "state": "Plateau",
      "ticketCategories": [
        { "name": "VIP", "price": "500000", "qty": "20","discount:":"0.0" },
        { "name": "Standard", "price": "100000", "qty": "100","discount:":"0.0" },
        { "name": "Budget", "price": "5000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-19:1000",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646893/dj-concert-modern-music-event-poster-design-template-d67abf0fb450a8d6c232f348dd6e6182_screen_c66kvb.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646893/dj-concert-modern-music-event-poster-design-template-d67abf0fb450a8d6c232f348dd6e6182_screen_c66kvb.jpg"],
      "createdAt": "2024-02-12:2100"
    },
    // {
    //   "objectId":"60a73c7db8bce667e4724d1b",
    //   "title": "Soulful Serenade",
    //   "eventDate": "2024-03-02:1200",
    //   "address": "999 Oak Street",
    //   "city": "Kaduna",
    //   "state": "Kaduna",
    //   "ticketCategories": [
    //     { "name": "VIP", "price": "750000", "qty": "25","discount:":"0.0" },
    //     { "name": "Regular", "price": "150000", "qty": "150","discount:":"0.0" },
    //     { "name": "Economy", "price": "5000", "qty": "300","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-20:1200",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/29176bd5-7c9a-461b-909b-5e55153819fd_qy6mn5.png",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646892/29176bd5-7c9a-461b-909b-5e55153819fd_qy6mn5.png","https://example.com/event/poster12_1.jpg"],
    //   "createdAt": "2024-02-13:2200"
    // },
    // {
    //   "objectId":"60a73c76b8bce667e4724d1a",
    //   "title": "Electronic Dance Fest",
    //   "eventDate": "2024-03-03:1400",
    //   "address": "1010 Pine Street",
    //   "city": "Warri",
    //   "state": "Delta",
    //   "ticketCategories": [
    //     { "name": "Diamond", "price": "2000000", "qty": "15","discount:":"0.0" },
    //     { "name": "Gold", "price": "500000", "qty": "100","discount:":"0.0" },
    //     { "name": "Silver", "price": "10000", "qty": "200","discount:":"0.0" }
    //   ],
    //   "ticketClosingDate": "2024-03-21:1400",
    //   "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1708646897/music-event-poster-with-photo_52683-42061_ti09k6.jpg",
    //   "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1708646897/music-event-poster-with-photo_52683-42061_ti09k6.jpg","https://example.com/event/poster13_2.jpg"],
    //   "createdAt": "2024-02-14:2300"
    // }
  ],
  "artists": [
    {
      "objectId":"60a73c92b8bce667e4724d1e",
      "title": "Kie Kie",
      "eventDate": "2024-02-27:1800",
      "address": "666 Maple Street",
      "city": "Akure",
      "state": "Ondo",
      "ticketCategories": [
        { "name": "Premium", "price": "500000", "qty": "30","discount:":"0.0" },
        { "name": "Standard", "price": "100000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-17:1800",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_4_Large_hxpect.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_4_Large_hxpect.jpg"],
      "createdAt": "2024-02-10:1900"
    },
    {
      "objectId":"60a73c8bb8bce667e4724d1d",
      "title": "Tuface",
      "eventDate": "2024-02-28:21",
      "address": "777 Pine Street",
      "city": "Benin City",
      "state": "Edo",
      "ticketCategories": [
        { "name": "Diamond", "price": "2000000", "qty": "10","discount:":"0.0" },
        { "name": "Platinum", "price": "750000", "qty": "50","discount:":"0.0" },
        { "name": "Gold", "price": "150000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-18:2100",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_Large_a0svej.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_Large_a0svej.jpg"],
      "createdAt": "2024-02-11:2000"
    },
    {
      "objectId":"60a73c84b8bce667e4724d1c",
      "title": "Shallipopi",
      "eventDate": "2024-03-01:1000",
      "address": "888 Elm Street",
      "city": "Jos",
      "state": "Plateau",
      "ticketCategories": [
        { "name": "VIP", "price": "500000", "qty": "20","discount:":"0.0" },
        { "name": "Standard", "price": "100000", "qty": "100","discount:":"0.0" },
        { "name": "Budget", "price": "5000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-19:1000",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_3_Large_rsjy6j.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_3_Large_rsjy6j.jpg"],
      "createdAt": "2024-02-12:2100"
    },
    {
      "objectId":"60a73c7db8bce667e4724d1b",
      "title": "DJ Dimpple Nipple",
      "eventDate": "2024-03-02:1200",
      "address": "999 Oak Street",
      "city": "Kaduna",
      "state": "Kaduna",
      "ticketCategories": [
        { "name": "VIP", "price": "750000", "qty": "25","discount:":"0.0" },
        { "name": "Regular", "price": "150000", "qty": "150","discount:":"0.0" },
        { "name": "Economy", "price": "5000", "qty": "300","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-20:1200",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_5_Large_yegckp.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_5_Large_yegckp.jpg"],
      "createdAt": "2024-02-13:2200"
    },
    {
      "objectId":"60a73c76b8bce667e4724d1a",
      "title": "Dan Dizzy",
      "eventDate": "2024-03-03:1400",
      "address": "1010 Pine Street",
      "city": "Warri",
      "state": "Delta",
      "ticketCategories": [
        { "name": "Diamond", "price": "2000000", "qty": "15","discount:":"0.0" },
        { "name": "Gold", "price": "500000", "qty": "100","discount:":"0.0" },
        { "name": "Silver", "price": "10000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-21:1400",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_1_Large_k9k2zm.jpg",
      "posters": ["https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_1_Large_k9k2zm.jpg"],
      "createdAt": "2024-02-14:2300"
    }
    ,
    {
      "objectId":"60a73c76b8bce667e4724d1a",
      "title": "Idahmas",
      "eventDate": "2024-03-03:1400",
      "address": "1010 Pine Street",
      "city": "Warri",
      "state": "Delta",
      "ticketCategories": [
        { "name": "Diamond", "price": "2000000", "qty": "15","discount:":"0.0" },
        { "name": "Gold", "price": "500000", "qty": "100","discount:":"0.0" },
        { "name": "Silver", "price": "10000", "qty": "200","discount:":"0.0" }
      ],
      "ticketClosingDate": "2024-03-21:1400",
      "eventBanner": "https://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_2_Large_wozkpq.jpg",
      "posters": ["hhttps://res.cloudinary.com/teaspoonapp/image/upload/v1709306720/light_2_Large_wozkpq.jpg"],
      "createdAt": "2024-02-14:2300"
    }
  ],
"nbHits":"13",
"status":"success"
}

