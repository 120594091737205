import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
//import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { eventsData } from "../eventsData";
// import { Link } from "react-router-dom";
import moment from "moment";



const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`m-0`;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0 xl:m-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg mx-auto xl:mx-0 bg-white p-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-2xl text-center leading-tight mt-8`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
// const PrimaryLink = styled(PrimaryLinkBase)`
//   ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
//   svg {
//     ${tw`ml-2 w-5 h-5`}
//   }
// `;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-16  bg-white`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4 p-4 bg-white`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
// const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;

export default () => {
  
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
            <CardImage imageSrc="https://res.cloudinary.com/teaspoonapp/image/upload/v1708698653/Lightup_ph_sb17er.jpg" />
              <HeadingTitle>About Lightup Tickets</HeadingTitle>
              <HeadingDescription>
              Lightup Tickets is an event ticketing platform that allows anyone to create, share, find and attend events that fuel their passions and enrich their lives. From music festivals, night parties, marathons, conferences, community rallies, and fundraisers, to gaming competitions, our platform provides all you need.
              </HeadingDescription>
            </HeadingInfoContainer>
          </HeadingColumn>
          {eventsData.liveEvents.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.posters[0]} />
                <CardText>
                  <CardHeader>
                    <CardType>Event Date:</CardType>
                    <CardPrice>
                      <CardPriceAmount>{moment(card.eventDate).format('MMMM Do YYYY')}</CardPriceAmount>
                    </CardPrice>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <TrendingIcon /> {card.address}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <TimeIcon />Ticket Closing Date: {moment(card.ticketClosingDate).format('MMMM Do YYYY')}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.city}
                    </CardMetaFeature>
                  </CardMeta>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
