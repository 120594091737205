import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 


import Header from "components/header";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";


import Footer from "components/Footer";

/* Hero */
const Row = tw.div`flex`;

const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;

const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-2xl text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center text-xl lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
 


//   const downloadUrl = "/ticket-page.pdf"
//   React.useEffect(() => {
//     var iframe = document.createElement("iframe");
//     iframe.style.display = "none";
//     iframe.src = downloadUrl
//     document.body.appendChild(iframe);
//   }, [])

  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <ContentWithPaddingXl>
          <HeroRow>
            <Heading>Oops, Sorry!</Heading>
            <Description tw="mt-12">Your ticket purchase was not successful. <br/>Click the link below to try again.</Description>
            <div tw="mt-12 text-center">
            <NavLink href="/cart">click here</NavLink>
            </div>
          </HeroRow>
        </ContentWithPaddingXl>
          <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
