import React, { useEffect } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../components/hero.js";
import SliderCard from "components/ThreeColSlider.js";
import Features from "components/ThreeColWithSideImage.js";
import MainFeature2 from "components/TwoColSingleFeatureWithStats2.js";
import FeatureStats from "components/ThreeColCenteredStatsPrimaryBackground.js";
// import Artists from "components/GridWithFeaturedPost.js";
import Footer from "components/Footer"
import Header from "components/header";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import { removeEvent } from "../redux/cartRedux";




export default()=> {

useEffect(()=> {
      removeEvent();
  }, [])

  return(
  
  <AnimationRevealPage>
    <Header />    
    <Hero />    
    <SliderCard />
    <Features />
    <MainFeature2 />
    <FeatureStats/>
   
    <SubscribeNewsLetterForm />
    <Footer />
  </AnimationRevealPage>
  
)};
