import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { Circles } from "react-loader-spinner";
import moment from "moment";


const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)`text-2xl`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
	${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
	&:hover {
		${tw`bg-gray-300 text-gray-700`}
	}
	${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabContent = tw(
	motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
	motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
	${(props) =>
		css`
			background-image: url("${props.imageSrc}");
		`}
	${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
	background-color: rgba(255, 255, 255, 0.5);
	${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-sm font-normal text-primary-400`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
	${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
	${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({ heading = "Events", events, status, error }) => {
	const currentDate = new Date();

	const upcomingEvents = events?.filter(
		(event) => new Date(event.eventDate) > currentDate
	);
	const pastEvents = events?.filter(
		(event) => new Date(event.eventDate) < currentDate
	);
	const featuredEvents = events?.filter((event) => event.featured === true);
	const tabs = {
		All: events,
		Featured: featuredEvents,
		Upcoming: upcomingEvents,
		Past: pastEvents,
	};

	const tabsKeys = Object.keys(tabs);
	const [activeTab, setActiveTab] = useState(tabsKeys[0]);

	if (status === "loading" || events === undefined || events === null) {
		
			<Circles
				height="180"
				width="180"
				color="#4a21cf"
				ariaLabel="circles-loading"
				wrapperStyle={{
					alignItems: "center",
					justifyContent: "center",
					margin: 10,
				}}
				wrapperClass=""
				visible={true}
			/>

	}

//   useEffect(() => {
//     if (error) {
//         swal({
//             title: "Error loading events!",
//             text: "Please reload the page!",
//             icon: "warning",
//             // buttons: true,
//             dangerMode: true,
      
//         });
//     }
// }, [error]);

	return (
		<Container>
			<ContentWithPaddingXl>
				<HeaderRow>
					<Header>{heading}</Header>
					<TabsControl>
						{Object.keys(tabs).map((tabName, index) => (
							<TabControl
								key={index}
								active={activeTab === tabName}
								onClick={() => setActiveTab(tabName)}
							>
								{tabName}
							</TabControl>
						))}
					</TabsControl>
				</HeaderRow>

				{tabsKeys.map((tabKey, index) => (
					<TabContent
						key={index}
						variants={{
							current: {
								opacity: 1,
								scale: 1,
								display: "flex",
							},
							hidden: {
								opacity: 0,
								scale: 0.8,
								display: "none",
							},
						}}
						transition={{ duration: 0.4 }}
						initial={activeTab === tabKey ? "current" : "hidden"}
						animate={activeTab === tabKey ? "current" : "hidden"}
					>
						{tabs[tabKey]?.length > 0 ? (tabs[tabKey].map((card, index) => (
							<CardContainer key={index}>
								<Card
									className="group"
									href={`/event-detail/${card._id}`}
									initial="rest"
									whileHover="hover"
									animate="rest"
								>
									<CardImageContainer imageSrc={card.eventBanner.url}>
										<CardHoverOverlay
											variants={{
												hover: {
													opacity: 1,
													height: "auto",
												},
												rest: {
													opacity: 0,
													height: 0,
												},
											}}
											transition={{ duration: 0.3 }}
										>
											<CardButton>Buy Now</CardButton>
										</CardHoverOverlay>
									</CardImageContainer>
									<CardText>
										<CardTitle>{card?.title}</CardTitle>
										<CardContent>
											{card?.address}, {card?.city}, {card?.state}
										</CardContent>
										<CardPrice>
											Event Date:{" "}
											{moment(card?.eventDate).format("Do MMM YYYY")}
										</CardPrice>
									</CardText>
									<CardButton style={{marginBlock: 20, marginInlineStart: 80}}>Buy Ticket</CardButton>
								</Card>

							</CardContainer>
						))) : (<div style={{width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center"}}> <h3 style={{fontSize: 24, fontWeight: "bold", textAlign: "center"}}>No Data Available </h3></div>)}
					</TabContent>
				))}
			</ContentWithPaddingXl>
			<DecoratorBlob1 />
			<DecoratorBlob2 />
		</Container>
	);
};
