import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "../images/nigeria-naira.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as DateIcon } from "feather-icons/dist/icons/calendar.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { Link } from "react-router-dom";
import { fetchEventsAsync } from "../redux/eventRedux";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import numbro from "numbro"
import { Circles } from 'react-loader-spinner'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { eventsData } from "../eventsData";
import TwoTrendingPreviewCardsWithImage from "./TwoTrendingPreviewCardsWithImage";



const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`text-2xl`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Card = tw.div`h-full flex! flex-col border max-w-sm rounded-tl-4xl rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded md:rounded-none md:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 px-4 sm:py-4`;
const TitleReviewContainer = tw.div`flex flex-row items-start sm:flex-row sm:justify-center sm:items-center`;
const DateContainer = tw.div`flex flex-row flex-wrap sm:mt-2 sm:justify-start sm:items-center`;
const Title = tw.h5`text-xl font-bold`;



const SecondaryInfoContainer = tw.div`flex flex-col justify-start mt-2 sm:mt-4`;
const IconWithText = tw.div`flex justify-start mb-2 my-2 sm:my-0`;
const IconWithText2 = tw.div`flex justify-start py-2 mt-3 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`flex justify-between w-5 h-5 rounded-full bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3 ml-1 mt-1 self-center align-middle`}
  }
`;
const IconContainer2 = styled.div`
  ${tw`flex justify-between`}
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto justify-end sm:text-lg rounded-none w-full rounded rounded-br-4xl py-3 sm:py-6`;


export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);

  const dispatch = useDispatch();
  const { events, status } = useSelector((state) => state.events);
  const [eventData, setEventData] = useState(null);
  const [show, setShow] = useState(false);

  // console.log(events)

  useEffect(() => {
    dispatch(fetchEventsAsync());
    
  }, []);

  

  
  useEffect(() => {
    
    if (status === "succeeded" && events?.length > 2) {
      
      setEventData(events);

    }else if (events?.length <= 2 && events?.length > 0){
        setEventData(events.concat(events));
    }else {
      setShow(true)
      
    }
  },[]);


  // useEffect(() => {
  //   if(events.lenght <=2 ) {
  //     setEventData(events.concat(events));
  //   }
  // }, [events]);

  if (status === 'loading'|| events === undefined || events === null) {
    return (<Circles
    height="180"
    width="180"
    color="#4a21cf"
    ariaLabel="circles-loading"
    wrapperStyle={{alignItems: 'center', justifyContent: 'center', margin: 10}}
    wrapperClass=""
    visible={true}
    />);
  }


  if (show) {
    return <div><TwoTrendingPreviewCardsWithImage /></div>
  }

 
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Get Your Tickets</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings} >
          {eventData?.map((event, index) => (
            <Card key={index} >
              <Link to={`/event-detail/${event._id}`}>
              <CardImage imageSrc={event?.posters ? event?.posters[0]?.url: event?.eventBanner?.url }/>
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{event.title}</Title>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{event.address} {event.city}</Text>
                  </IconWithText>
                  <IconWithText2>
                    <IconContainer2>
                  <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{numbro(event?.ticketCategories[0]?.price).format({
                                thousandSeparated: true,
                            })}</Text>
                    </IconContainer2>
                    <Text>({event?.ticketCategories[0]?.name})</Text>
                  </IconWithText2>
                </SecondaryInfoContainer>
                <DateContainer>
                <IconContainer>
                      <DateIcon />
                    </IconContainer>
                    <Text>Event date: {moment(event.eventDate).format('MMM Do YYYY h:mma')}</Text>
                    </DateContainer>
                    <DateContainer>
                      <IconWithText>
                    <IconContainer>
                      <DateIcon />
                    </IconContainer>
                  <Text>Ticket closes: {moment(event.ticketClosingDate).format("MMM Do YYYY, ha")}</Text>
                      </IconWithText>
                  </DateContainer>
              </TextInfo>
              <PrimaryButton>Buy Ticket</PrimaryButton>
              </Link>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
