import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import numbro from "numbro"
import { mobile } from "../styles/responsive";
import { removeEvent } from "../redux/cartRedux";

import html2canvas from "html2canvas";
import swal from "sweetalert";
import jsPDF from "jspdf";
import Barcode from "react-barcode";
import moment from "moment";

const API = process.env.REACT_APP_API_URL;
const Info = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;
	align-items: center;
	height: 80%;
	${mobile({
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	})}
`;

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	text-align: center;
	margin: 20px 0;
	color: #fff;
`;

const TwoColumnContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`;

const Event = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: space-between;
	/* ${mobile({ gap: 0 })} */
`;

const EventDetail = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	align-content: center;
	${mobile({ flexDirection: "column" })}
`;

// const Image = styled.img`
// 	width: 90%;
// 	object-fit: contain;
// 	${mobile({ width: "100%" })}
// `;

const Details = styled.div`
	padding: 20px;
	gap: 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-betweeen;
	margin-bottom: 5px;
	width: 90%;
	margin-top: 5px;
	background-color: #ffffff;
	border: 1px solid #c5c4c47a;
	${mobile({ width: "90vw", gap: "20px" })}
`;

const EventName = styled.span`
	display: flex;
	justify-content: space-between;
	flex: 1;
`;

const EventId = styled.span`
	display: flex;
	justify-content: space-between;
`;

const TicketType = styled.span`
	/* font-weight: 500;
  text-align: left; */
	display: flex;
	justify-content: space-between;
`;

const Hr = styled.hr`
	background-color: #b9b6b6;
	border: none;
	height: 1px;
`;

const Summary = styled.div`
	flex: 1;
	border: 0.5px solid lightgray;
	/* border-radius: 10px; */
	padding: 20px;
	align-items: center;
	justify-content: "space-between";
	width: 90%;
	margin: 2px;
	background-color: #ffffff;
	${mobile({ width: "90vw" })}
`;

const SummaryTitle = styled.h1`
	font-weight: 500;
	font-size: 18px;
	text-align: center;
`;

const SummaryItem = styled.div`
	margin: 20px 0px;
	display: flex;
	justify-content: space-between;
	font-weight: ${(props) => props.type === "total" && "500"};
	font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
	width: 40%;
	padding: 10px;
	height: 80px;
	border-radius: 10px;
	margin: 10px;
	align-items: center;
	background-color: black;
	color: white;
	font-weight: 500;
`;

const Ticket = () => {
	const [loader, setLoader] = useState(false);
	const [eventDetails, setEventDetails] = useState("");
	const { ticket } = useSelector((state) => state.ticket);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/* console.log(ticket) */

	const getEventName = async () => {
		try {
			const { data } = await axios.get(`${API}/events/${ticket.eventRef}`);
			setEventDetails(data.data);
		} catch (e) {
			swal({
				title: "Network Error!",
				text: "Check your internet connection and reload page",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			});
		}
	};

	useEffect(() => {
		if (ticket !== null) {
			dispatch(removeEvent());
		}
	}, []);

	useEffect(() => {
		getEventName();
	}, [ticket.eventRef]);

	const downloadTicket = () => {
		const capture = document.querySelector(".ticket-receipt");
		setLoader(true);
		html2canvas(capture).then((canvas) => {
			const imgData = canvas.toDataURL("img/png");
			const doc = new jsPDF("p", "px", "a4", false);
			const componentWidth = doc.internal.pageSize.getWidth();
			const componentHeight = doc.internal.pageSize.getHeight();
			doc.addImage(imgData, "PNG", 0, 0, componentWidth/1.68, componentHeight);
			setLoader(false);
			doc.save("ticket.pdf");
			/* dispatch(removeEvent())  
        navigate("/"); */
		});
	};

	if (!ticket || ticket === undefined) {
		swal({
			title: "Network Error!",
			text: "Check your internet connection and reload page",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		});
	}

	return (
		<>
			<EventDetail >
				<div
				className="ticket-receipt"
					style={{
						width: "450px",
						padding: "none",
						margin: "none",
						backgroundColor: "#585756",
						border: "5px dotted #fbfbfb",
						alignSelf: "center"
					}}
				>
					<Title>Your Ticket</Title>
					<TwoColumnContainer>
						<Barcode value="https://lightupticket.com/" />
					</TwoColumnContainer>
					<Info>
						<Details>
							<SummaryTitle>TICKET DETAILS</SummaryTitle>
							<Event>
								<TicketType>
									<div>Ticket Category:</div>{" "}
									<div>{ticket?.ticketCategory}</div>
								</TicketType>
								<Hr />
								<EventId>
									<span>Event Date :</span>{" "}
									<span>
										{moment(eventDetails?.eventDate).format("Do MMM YYYY h:mma")}
									</span>
								</EventId>
								<Hr />
								<EventName>
									<div>Event:</div> <div>{eventDetails?.title}</div>
								</EventName>
								<Hr />
								<EventName>
									<div>Venue:</div> <div>{eventDetails?.address}, {eventDetails?.city}, {eventDetails?.state} </div>
								</EventName>
								<Hr />
								<EventName>
									<div>Name:</div> <div>{ticket?.name}</div>
								</EventName>
								<Hr />
								<EventName>
									<div>Email:</div> <div>{ticket?.email}</div>
								</EventName>
								<Hr />
								<EventName>
									<div>Phone number:</div> <div>{ticket?.phone}</div>
								</EventName>
							</Event>
						</Details>

						<Summary>
							<SummaryTitle>PRICE SUMMARY</SummaryTitle>

							<Hr />
							<SummaryItem>
								<SummaryItemText>Ticket Ref No:</SummaryItemText>
								<SummaryItemPrice> {ticket?.referenceNo}</SummaryItemPrice>
							</SummaryItem>
							<Hr />
							<SummaryItem>
								<SummaryItemText>Number of Tickets:</SummaryItemText>
								<SummaryItemPrice>{ticket?.numberOfTickets}</SummaryItemPrice>
							</SummaryItem>
							<Hr />
							<SummaryItem type="total">
								<SummaryItemText>Amount Paid:</SummaryItemText>
								<SummaryItemPrice>₦ {numbro(ticket.amountPaid).format({
                                  thousandSeparated: true,
                                  mantissa: 2
                              })}</SummaryItemPrice>
							</SummaryItem>
						</Summary>
					</Info>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyItem: "center",
						alignContent: "top",
						marginTop: "60px",
					}}
				>
					<Button
						style={{ backgroundColor: "#d5d4d4", color: "black" }}
						onClick={() => navigate("/")}
					>
						Back to Lightup Tickets
					</Button>
					<Button onClick={downloadTicket} disabled={!(loader === false)}>
						{loader ? <span>Downloading</span> : <h4>Download Ticket</h4>}
					</Button>
				</div>
			</EventDetail>
		</>
	);
};

export default Ticket;
