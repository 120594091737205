import { Add, Remove } from "@mui/icons-material";
import Footer from "../components/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { mobile } from "../styles/responsive";
import { useEffect, useState } from "react";
import { addEvent } from "../redux/cartRedux";
import { useDispatch } from "react-redux";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/header";
import { Circles } from "react-loader-spinner";
import { Navigate, useNavigate } from "react-router-dom";
import { Container } from "components/misc/Layouts";
import { getEvent } from "api/eventsApi";
import { useParams } from "react-router-dom";
import numbro from "numbro";
import Select from "react-select";
import styled from "styled-components";
import swal from "sweetalert";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import tw from "twin.macro";
import moment from "moment";

const TwoColumnContainer = styled.div`
	${tw`flex items-center sm:items-stretch sm:flex-row flex-wrap sm:justify-center max-w-screen-lg mx-auto py-20 md:py-24 space-x-6`}
`;

const ImgContainer = styled.div`
	flex: 1;
	${mobile({ padding: "10px" })}
`;

const Image = styled.img`
	width: 100%;
	height: 80vh;
	object-fit: cover;
	${mobile({ height: "20%", width: "90vw", flex: 1, flexDirection: "column" })}
`;

const InfoContainer = styled.div`
	flex: 1;
	margin-top: 20px;
	padding: 0px 10px;
	${mobile({ padding: "10px", marginTop: "5px" })}
`;

const Title = styled.h1`
	font-weight: 700;
	font-size: 32px;
	${mobile({ fontSize: "25px" })}
`;

const Desc = styled.p`
	margin: 20px 0px;
`;

const Price = styled.span`
	font-weight: 400;
	font-size: 18px;
`;

const FilterContainer = styled.div`
	width: 100%;
	margin: 20px 0px;
	display: flex;
	align-items: left;
	justify-content: space-between;
	${mobile({ width: "100%" })}
`;

const Filter = styled.div`
	display: flex;
	width: 80%;
	justify-content: space-between;
	align-items: left;
	${mobile({ width: "100%" })}
`;

const FilterTitle = styled.span`
	font-size: 17px;
	font-weight: 300;
`;

const AddToCartContainer = styled.span`
	justify-content: center;
	display: inline-flex;
	padding: 5px;
	/* width: 70%; */
	align-items: center;
`;

const CustomerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 5px;
	gap: 12;
	/* align-items: left; */
	justify-content: space-between;
`;

const CustomerInput = styled.input`
	width: 100%;
	display: flex;
	height: 2.2rem;
	text-align: left;
	border: 1px solid #cecece84;
	color: black;
`;

const PrimaryButton = tw(
	PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const AddContainer = styled.div`
	/* width: 70%; */
	display: flex;
	margin-top: 35px;
	align-items: center;
	justify-content: space-between;
	${mobile({ width: "100%" })}
`;

const AmountContainer = styled.div`
	display: flex;
	align-items: center;
	font-weight: 700;
`;

const Amount = styled.span`
	width: 30px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid teal;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 5px;
`;

const Button = styled.button`
	padding-left: 5px;
	padding-right: 5px;
	width: 140px;
	border: 1px solid #cec9c9;
	background-color: #2927af;
	cursor: pointer;
	font-weight: 500;
	color: #ffffff;
	&:hover {
		background-color: #000000;
		border: 1px solid black;
	}
`;

const validationSchema = Yup.object().shape({
	customerName: Yup.string()
		.max(35, "Maximum 35 characters allowed")
		.required("Customer name is required"),
	customerEmail: Yup.string()
		.email("Invalid email")
		.matches(
			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
			"Invalid email format"
		)
		.min(14, "Invalid email")
		.max(30, "Invalid email")
		.required("Email is required"),
	customerPhone: Yup.string()
		.min(14, "Invalid phone number")
		.max(16, "Invalid phone number")
		.matches(/^\+[1-9]\d{1,14}$/, "Invalid phone number")
		.required("Phone number is required"),
});

const EventDetails = () => {
	const [quantity, setQuantity] = useState(1);
	const [ticketCategory, setTicketCategory] = useState("");
	const [selectedCategory, setSelectedCategory] = useState(null)
	
	const [priceValue, setPriceValue] = useState(0);
	const [cart, setCart] = useState(null);
	const [options, setOptions] = useState(null);
	const [event, setEvent] = useState(null);
	
	const [customerDetails, setCustomerDetails] = useState({
		customerEmail: "",
		customerName: "",
		customerPhone: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		const singleEvent = async () => {
			const eventByID = await getEvent(id);
			if (eventByID) {
				setEvent(eventByID);
			} else {
				swal({
					title: "Error loading selected event!",
					text: "Please reload the page or select another event!",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then(() => {
					window.location.reload();
				});
			}
		};

		singleEvent();
	}, [id]);

	useEffect(() => {
		const restructureArray = (ticketCategories) => {
			return ticketCategories?.map((category) => ({
				label: `${category?.name}`,
				value: `${category?.price}`,
			}));
		};

		const newoptions = restructureArray(event?.ticketCategories);
		setOptions(newoptions);
	}, [event]);

	useEffect(() => {
		const findPrice = async () => {
			const selectedCat = await event?.ticketCategories?.find(
				(category) => category.name === ticketCategory?.label
			);
			setSelectedCategory(selectedCat)
			const ticketValue = selectedCat?.price;
			setPriceValue(Number(ticketValue));
			console.log(ticketValue);
		};
		findPrice();
	}, [ticketCategory, event?.ticketCategories]);



	const handleChange = (e, setFieldValue) => {
		const { name, value } = e.target;
		// Update Formik state
		setFieldValue(name, value);
		// Update local state
		setCustomerDetails((prevDetails) => ({
			...prevDetails,
			[name]: value,
		}));
	};

	const handleQuantity = (type) => {
		if (type === "dec") {
			quantity > 1 && setQuantity((prevQuantity) => prevQuantity - 1);
		} else {
			if (type === "inc") {
				setQuantity((prevQuantity) => prevQuantity + 1);
			}
		}
	};

	const handleClick = (values, { setSubmitting }) => {
		if( quantity > selectedCategory.qty || selectedCategory.qty < 1 ) {
			return swal({
				title: "Maximum number of available tickets for this category exceeded!",
				text: "Ticket category out of stock!",
				icon: "warning",
				dangerMode: true,
			}).then(()=> {
				navigate(`/event-detail/${id}`);
			})
	
		};

		if (ticketCategory !== "") {
			
			setCart(
				dispatch(
					addEvent({
						...event,
						quantity,
						selectedCategory,
						ticketCategory,
						priceValue,
						customerDetails,
					})
				)
			);
		} else if(ticketCategory === "") {
			swal({
				title: "Select a ticket category!",
				text: "To proceed to cart select a ticket category!",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			});
		}
	};

	if (!event) {
		return (
			<Circles
				height="180"
				width="180"
				color="#4a21cf"
				ariaLabel="circles-loading"
				wrapperStyle={{
					alignItems: "center",
					justifyContent: "center",
					margin: 10,
				}}
				wrapperClass=""
				visible={true}
			/>
		);
	}


	return (
		<AnimationRevealPage>
			<Header />
			<Container>
				{cart && <Navigate to="/cart" replace={true} />}

				<TwoColumnContainer>
					<ImgContainer>
						<Image src={event.posters ? event?.posters[0]?.url: event?.eventBanner } />
					</ImgContainer>
					<InfoContainer>
						<Title>{event?.title}</Title>
						<Desc>
							Event Date: {moment(event?.eventDate).format("Do MMM YYYY h:mma")}{" "}
						</Desc>
						<Desc>
							Venue: {event?.address} {event?.city} {event?.state}
						</Desc>
						<FilterContainer>
							<Filter>
								<FilterTitle>Ticket Category</FilterTitle>
								<Select
									defaultValue={ticketCategory}
									onChange={setTicketCategory}
									options={options}
									width="100%"
									placeholder="Select category"
								/>
							</Filter>
						</FilterContainer>
						<FilterContainer>
							<Filter>
								<FilterTitle>Price:</FilterTitle>
								<Price>
									₦{" "}
									{priceValue
										? numbro(priceValue).format({
												thousandSeparated: true,
												mantissa: 2,
										  })
										: 0}
								</Price>
							</Filter>
						</FilterContainer>
						<FilterContainer>
							<Filter>
								<FilterTitle>Available no of tickets:</FilterTitle>
								<FilterTitle>{selectedCategory?.qty}</FilterTitle>
							</Filter>
						</FilterContainer>
						<CustomerContainer>
							<Formik
								initialValues={{
									customerEmail: "",
									customerName: "",
									customerPhone: "",
								}}
								validationSchema={validationSchema}
								onSubmit={handleClick}
							>
								{({ isSubmitting, setFieldValue }) => (
									<Form>
										<div style={{ justifyContent: "space-between" }}>
											<p as={FilterTitle}>Name:</p>
											<Field
												as={CustomerInput}
												type="text"
												name="customerName"
												onChange={(e) => handleChange(e, setFieldValue)}
												placeholder="Enter your fullName"
											/>
										</div>
										<ErrorMessage
											name="customerName"
											style={{ color: "red" }}
											component="div"
										/>
										<div
											style={{ marginTop: 10, justifyContent: "space-between" }}
										>
											<p as={FilterTitle}>Phone:</p>
											<Field
												as={CustomerInput}
												type="text"
												name="customerPhone"
												onChange={(e) => handleChange(e, setFieldValue)}
												placeholder="+2348031234567"
											/>
										</div>
										<ErrorMessage
											name="customerPhone"
											style={{ color: "red" }}
											component="div"
										/>
										<div
											style={{ marginTop: 10, justifyContent: "space-between" }}
										>
											<p as={FilterTitle}>Email:</p>
											<Field
												as={CustomerInput}
												type="email"
												name="customerEmail"
												onChange={(e) => handleChange(e, setFieldValue)}
												placeholder="Enter your email address"
											/>
										</div>
										<ErrorMessage
											name="customerEmail"
											style={{ color: "red" }}
											component="div"
										/>

										<AddToCartContainer>
											<AddContainer>
												<AmountContainer>
													<Remove onClick={() => handleQuantity("dec")} />
													<Amount>{quantity}</Amount>
													<Add onClick={() => handleQuantity("inc")} />
												</AmountContainer>
											</AddContainer>
											<PrimaryButton as={Button} disabled={isSubmitting}>
												Continue to Pay{" "}
											</PrimaryButton>
										</AddToCartContainer>
									</Form>
								)}
							</Formik>
						</CustomerContainer>
					</InfoContainer>
				</TwoColumnContainer>
			</Container>
			<Footer />
		</AnimationRevealPage>
	);
};

export default EventDetails;
