import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// eslint-disable-next-line
import styled from "styled-components";
import Header from "components/header";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import Footer from "components/Footer";
import Ticket from "./TicketPage";

/* Hero */
const Row = tw.div`flex`;


const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-2xl text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center text-xl lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {


  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeroRow>
            <Heading>Thank You!</Heading>
            <Description tw="mt-12">Your ticket purchase was successful. <br/>Click the download button below to download your ticket.</Description>
            <div tw="mt-12 text-center">
            {/* <NavLink href={ticketUrl}>click here</NavLink> */}
            <Ticket />
             
              
            </div>
          </HeroRow>
        </ContentWithPaddingXl>
          <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
