import { createSlice } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    ticket: null,
  },
  reducers: {
    safeTicket: (state, action) => {
      state.ticket = action.payload;
      
    },
  },
});

export const { safeTicket } = ticketSlice.actions;
export default ticketSlice.reducer;
