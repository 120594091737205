
import { useSelector } from "react-redux";

// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";


const heroBanners = [
	{
		eventBanner: {
			url: "https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80",
			key: 1,
			title: "Banner1",
		}
	},
	{
		eventBanner: {
			url:
			"https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80",
		key: 2,
		title: "Banner3",
		}
	},
	{
		eventBanner: {
			url:
			"https://images.unsplash.com/photo-1543365067-fa127bcb2303?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80",
		key: 3,
		title: "Banner3",
		}
	},
	{
		eventBanner: {
			url:
			"https://images.unsplash.com/photo-1543365067-fa127bcb2303?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80",
		key: 6,
		title: "Banner6",
		}
	},
];

const Sliders = () => {
	const { events, status } = useSelector((state) => state.events);
	const [sliderSource, setSliderSource] = useState([]);

	useEffect(() => {
        const getSliderData = async () => {
            if (events.length > 1) {
                const featuredEvents = events.filter((event) => event.featured);
                setSliderSource(featuredEvents);
            } else {
                setSliderSource(heroBanners);
            }
        };

        if (status === "loading" || events === undefined || events === null) {
            return; // Avoid unnecessary rendering during loading
        }

        getSliderData(); // Call after checking loading state
    }, [events, status]);


	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		adaptiveHeight: true,
		arrows: true,
	  };

	  return (
        <Box sx={{}}>
            {status === "loading" ? (
                <Circles
                    height="180"
                    width="180"
                    color="#4a21cf"
                    ariaLabel="circles-loading"
                    wrapperStyle={{
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 10,
                    }}
                    wrapperClass=""
                    visible={true}
                />
            ) : (
                <Slider {...settings}>
                    {sliderSource.map((slider, index) => (
                        <div key={index} style={{ alignItems: "center" }}>
                            <img
                                src={slider.eventBanner?.url}
                                width="100%"
                                height="auto"
                                alt={slider.title}
                                style={{ objectFit: "fill" }}
                            />
                        </div>
                    ))}
                </Slider>
            )}
        </Box>
    );
};

export default Sliders;
