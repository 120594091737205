import axios from "axios";
const API = process.env.REACT_APP_API_URL


export const getAllEvents = async() => {
    
    try {
        const {data} = await axios.get(`${API}/events`)
      const allEvents = data.data.events
       console.log("AllEvents:", allEvents)
      return allEvents
    } catch (error) {
        console.log(error)
    }
   
}
export const getEvent = async(eventId) => {
    
    try {
        const {data} = await axios.get(`${API}/events/${eventId}`)
      const eventById = data.data
       console.log("Event:", eventById)
      return eventById
    } catch (error) {
        console.log(error)
    }
   
}

// Function to filter events based on query parameters
export const filterEvents = async (query) => {
    try {
        const { data } = await axios.get(`${API}/events`, { params: query });
        const filteredEvents = data.data.events;
        console.log("Filtered Events:", filteredEvents);
        return filteredEvents;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// Function to sort events by date
export const sortEventsByDate = (events) => {
    return events.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));
};

// Function to get upcoming events
// export const getUpcomingEvents = async () => {
//     try {
//         const allEvents = await getAllEvents();
//         const currentDate = new Date();
//         const upcomingEvents = allEvents.filter(event => new Date(event.eventDate) > currentDate);
//         console.log("Upcoming Events:", upcomingEvents);
//         return upcomingEvents;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// };

// Function to get past events
// export const getPastEvents = async () => {
//     try {
//         const allEvents = await getAllEvents();
//         const currentDate = new Date();
//         const pastEvents = allEvents.filter(event => new Date(event.eventDate) < currentDate);
//         console.log("Past Events:", pastEvents);
//         return pastEvents;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// };