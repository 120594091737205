import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
//import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/header.js";
import Footer from "../components/Footer.js";
import ContactUsForm from "../components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "../components/ThreeColContactDetails.js";




export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
